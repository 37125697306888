<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <el-row>
        <el-col :md="14">
          <el-button type="success"  size="small" disabled>编辑</el-button>
          <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
        </el-col>
        <el-col :md="10" class="vd_export2">
          <div>
            <span style="margin-right:2px;">材料编号:</span>
            <el-input size="small" style="width:200px;" v-model="mtrbForm.mtrb_no" disabled show-word-limit></el-input>
          </div>
          <span class="vg_tag_position">
            <span class="vg_tag_label">状态：</span>
            <el-tag :type="helper.getStatusName(mtrbForm.status).type">{{helper.getStatusName(mtrbForm.status).name}}</el-tag>
          </span>
        </el-col> 
      </el-row>
    </div> 
    <el-form ref="mtrbForm" :model="mtrbForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row>
        <el-col :md="16">
          <el-row class="vg_mb_5">
            <el-col :md="24">
              <el-form-item label="材料名称" prop="mtrb_name">
                <el-input maxlength="50" v-model="mtrbForm.mtrb_name" placeholder="请填写材料名称" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="24">
              <el-form-item label="材料规格" prop="mtrb_spec">
                <el-input maxlength="255" type="textarea" :rows="4" v-model="mtrbForm.mtrb_spec" placeholder="请填写材料规格" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="材料类别" prop="mtrb_type">
                <el-input maxlength="30" :value="mtrbForm.mtrb_type ===1?'辅料':'包材'" placeholder="请填写材料类别" show-word-limit>
                </el-input>
                <!-- <el-select filterable v-model="mtrbForm.mtrb_type" placeholder="请选择材料类别">
                  <el-option v-for="item in mtrbTypeList" :key="item.id" :label="item.label" :value="item.id">
                  </el-option>
                </el-select> -->
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="采购价格" prop="mtrb_price">
                <el-input maxlength="9" v-model="mtrbForm.mtrb_price" @input="mtrbForm.mtrb_price = helper.keepTNum1(mtrbForm.mtrb_price)" @blur="mtrbForm.mtrb_price = helper.calcPrice(mtrbForm.mtrb_price, 4, 10000)" placeholder="请填写采购价格" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="供应商简称" prop="supp_id">
                <el-input maxlength="30" v-model="mtrbForm.supp_abbr" placeholder="请填写供应商简称" show-word-limit>
                </el-input>
                <!-- <el-select filterable v-model="mtrbForm.supp_id" placeholder="请选择供应商简称">
                  <el-option v-for="item in suppList" :key="item.supp_id" :label="item.supp_abbr" :value="item.supp_id">
                  </el-option>
                </el-select> -->
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="材料单位" prop="mtrb_unit">
                <el-input maxlength="30" v-model="mtrbForm.mtrb_unit" placeholder="请填写材料单位" show-word-limit>
                </el-input>
                <!-- <el-select filterable v-model="mtrbForm.mtrb_unit" placeholder="请选择材料单位">
                  <el-option v-for="item in mtrbUnitList" :key="item.id" :label="item.param1" :value="item.param1">
                  </el-option>
                </el-select> -->
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="增值税率" prop="mtrb_inrate">
                <el-input maxlength="9" v-model="mtrbForm.mtrb_inrate" @input="mtrbForm.mtrb_inrate = helper.keepTNum1(mtrbForm.mtrb_inrate)" @blur="mtrbForm.mtrb_inrate = helper.calcPrice(mtrbForm.mtrb_inrate, 4, 10000)" placeholder="请填写增值税率" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="起订量" prop="mtrb_moq">
                  <el-input v-model="mtrbForm.mtrb_moq" @input="mtrbForm.mtrb_moq = helper.keepTNum1(mtrbForm.mtrb_moq)" maxlength="10" placeholder="请填写起订量" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="24">
              <el-form-item label="备注" prop="mtrb_remark">
                <el-input maxlength="255" v-model="mtrbForm.mtrb_remark" type="textarea" :rows="4" show-word-limit placeholder="请填写备注">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col> 
        <el-col :md="8">
          <el-form-item label="材料主图" prop="imge_id">
            <div class="vd_dis">
              <div>
                <el-upload class="upload-demo" drag :action="uploadUrl" :data="dataBody" :show-file-list="false"
                  :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" ref="uploadMutiple">
                  <imgPopover v-if="mtrbForm.imge_id" :imgeUrl="mtrbForm.imge_url" :imgeUrlO="mtrbForm.imge_urlO">
                  </imgPopover>
                  <i v-else class="el-icon-upload avatar-uploader-icon vg_pointer" size="40px"></i>
                </el-upload>
                <span>大小不能超过10MB</span>
              </div>
              <div id="preview" @paste="handlePaste" class="vd_flex_right">
                <span><i class="el-icon-s-opportunity" style="color:#FB894C"></i>点击此处粘贴</span>
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="mtrbForm.stff_name" :typeFlag="1"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {mtrbAPI} from "@api/modules/mtrb";
import { imgeAPI } from "@api/modules/imge";
import { suppAPI } from "@api/modules/supp";
import {optnAPI} from "@api/modules/optn";
import inputUser from "@/views/component/inputUser";
import helper from "@assets/js/helper";
import imgPopover from "@/views/component/imgPopover";

export default {
  name: 'mtrbEditMain',
  components: {
    inputUser,
    imgPopover,
  },
  data() {
    return {
      rules: {
        imge_id: [
          { required: true, trigger: 'blur', message: ' ' },
        ],
        mtrb_type: [
          { required: true, trigger: ['blur', 'change'], message: ' ' },
        ],
        supp_id: [
          { required: true, trigger: ['blur', 'change'], message: ' ' },
        ],
        mtrb_price: [
          { required: true, trigger: 'blur', message: ' ' },
        ],
        mtrb_unit: [
          { required: true, trigger: ['blur', 'change'], message: ' ' },
        ],
        mtrb_inrate: [
          { required: true, trigger: 'blur', message: ' ' },
        ],
      },
      mtrbForm:{
        mtrb_no: null,
        mtrb_id: null,
        mtrb_name: null,
        mtrb_type: null,
        mtrb_spec: null,
        mtrb_price: null,
        supp_id: null,
        mtrb_unit: null,
        mtrb_inrate: null,
        mtrb_moq: null,
        mtrb_remark: null,
        imge_id: null,
        imge_url: "",
        imge_urlO: "",
      },
      show: false,
      uploadUrl: helper.modePrepend(imgeAPI.uploadImage),
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 800,
        y: 800,
      },
      imgForm: {
        imge_id: null,
        imge_url: null,
        imge_urlO: null,
      },
      isShow: true,
      btn:{},
      stffForm:{},
      loadingFlag:true,
      mtrbUnitList: [],
      suppList:[],
      mtrbTypeList:[
        {id:1,label:'辅料'},
        {id:2,label:'包材'},
      ],
    }
  },
  created() {
    this.initData()
  },
  watch: {
  },
  methods:{
    initData(){
      this.loadingFlag = false
      this.getmtrbInfo();
      // this.getMtrbRankList()
      // this.getSupp()
    },
    //启用编辑
    openEdit(){
      this.isShow = false;
      this.$emit('isShow',this.isShow)
    },
    //撤销编辑
    closeEdit(){
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.isShow = true;
        this.$emit('isShow',this.isShow)
        this.show = true;
        this.initData()
        this.$message({
          type: 'info',
          message: '已撤销编辑!'
        });
      }).catch(()=>{})
    },
    //提交表单
    submit(formName){
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            this.$message.warning("请把必填内容补充完整");
            return false;
          }
        });
      }).catch(()=>{})
    },
    // 保存
    saveInfo() {
      const props = JSON.parse(this.$UrlEncode.decode(this.$route.query.key))
      let mtrbForm = JSON.parse(JSON.stringify(this.mtrbForm))
      mtrbForm.mtrb_id = props.form_id
      mtrbForm.personal = this.$cookies.get('userInfo').personal
      post(mtrbAPI.editMtrb,mtrbForm)
      .then(res => {                                                     
        if(res.data.code === 0){
          this.$message({
            type:'success',
            message:'保存成功'
          })
          this.isShow = true;
          this.$emit('isShow',this.isShow)
          this.initData()
        }else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
          this.initData()
        }
      })
      .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 刷新按钮
    buttonRefresh(){
      this.initData()
    },
    //取消
    cancel(){
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.resetForm('mtrbForm')
        this.$router.go(-1)
        this.$message({
          type: 'info',
          message: '已取消新增!'
        });
      }).catch(()=>{})
    },
    //获取供应商信息
    getSupp() {
      get(suppAPI.getSuppsV1).then(res => {
        if (res.data.code === 0) {
            this.suppList = res.data.data
            return
        }
        this.$message.error(res.data.msg)
      }).catch(res => {
        this.$message.error(res.data.msg)
      })
    },
    // 获取稿件等级
    getMtrbRankList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10008 })
        .then((res) => {
          if (res.data.code === 0) {
            this.mtrbUnitList = res.data.data.form.optn_cntt_list
          }
        }).catch(() => { })
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 成功上传
    handleAvatarSuccess(res) {
      if (res.code === 0) {
        let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
        let urlEng = JSON.parse(JSON.stringify(res.data.imge_url));
        this.mtrbForm.imge_id = res.data.imge_id;
        this.mtrbForm.imge_url = this.helper.picUrl(urlEng, "m", updateTime);
        this.mtrbForm.imge_urlO = this.helper.picUrl(urlEng, "l", updateTime);
      } else {
        let mg = res.msg;
        let tp = "error";
        this.$message({ message: mg, type: tp });
      }
    },
    // 上传头像前
    beforeAvatarUpload(res) {
      const isLt5M = res.size /1024/1024 < 10
      if (!isLt5M) {
        this.$message.error('图片大小不得超过 10MB!');
        return false
      }
    },
    handlePaste(event) {
      if(!this.isShow){
        const items = (event.clipboardData || window.clipboardData).items;
        let file = null;
        if (!items || items.length === 0) {
            this.$message.error("当前浏览器不支持本地或请打开图片再复制");
            return;
        }
        // 搜索剪切板items
        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf("image") !== -1) {
                file = items[i].getAsFile();
                break;
            }
        }
        if (!file) {
            this.$message.error("粘贴内容非图片");
            return;
        }
        this.$refs.uploadMutiple.handleStart(file);// 将粘贴过来的图片加入预上传队列
        this.$refs.uploadMutiple.submit(); // 提交图片上传队列
      }else{
        this.$message.warning('请先点击编辑')
      }
    },
    // 获取表单信息
    getmtrbInfo() {
      // const props = JSON.parse(this.$UrlEncode.decode(this.$route.query.key))
      // this.mtrbForm.mtrb_id = props.form_id;
      this.mtrbForm.mtrb_id = this.$route.query.form_id;
      get(mtrbAPI.getMtrbById,{mtrb_id:this.mtrbForm.mtrb_id})
      .then(res=>{
        if (res.data.code === 0) {
          this.mtrbForm = res.data.data.form;
          this.btn = res.data.data.btn;
          this.stffForm.stff_name = this.mtrbForm.stff_name;
          this.stffForm.dept_name = this.mtrbForm.dept_name;
          this.stffForm.dept_team_name = this.mtrbForm.dept_team_name;
          this.stffForm.user_id = this.mtrbForm.user_id;
          this.stffForm.dept_id = this.mtrbForm.dept_id;
          this.stffForm.stff_id = this.mtrbForm.stff_id;
          this.mtrbForm.mtrb_inrate = this.helper.haveFour(this.mtrbForm.mtrb_inrate);
          this.mtrbForm.mtrb_price = this.helper.haveFour(this.mtrbForm.mtrb_price);
          let urlEng = JSON.parse(JSON.stringify(this.mtrbForm.imge_url));
          if (this.mtrbForm.imge_id) {
              this.dataBody.imge_id = this.mtrbForm.imge_id;
            } else {
              this.dataBody.imge_id = 0;
            }
            let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
            this.mtrbForm.imge_url = this.helper.picUrl(
              urlEng,
              "l",
              updateTime
            );
            this.mtrbForm.imge_urlO = this.helper.picUrl(
              urlEng,
              "l",
              updateTime
            );
            if (this.show) {
              this.mtrbForm.imge_id = this.imgForm.imge_id;
              this.mtrbForm.imge_url = this.imgForm.imge_url;
              this.mtrbForm.imge_urlO = this.imgForm.imge_urlO;
              this.show = false;
            }
            this.imgForm.imge_url = JSON.parse(
              JSON.stringify(this.mtrbForm.imge_url)
            );
            this.imgForm.imge_id = JSON.parse(
              JSON.stringify(this.mtrbForm.imge_id)
            );
            this.imgForm.imge_urlO = JSON.parse(
              JSON.stringify(this.mtrbForm.imge_urlO)
            );
          setTimeout(() => {
            this.loadingFlag = false
          }, 500);
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        }
      })
      .catch(res => {
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    }
  }
}
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

::v-deep.avatar-uploader .el-upload--text {
  width: 300px;
  height: 300px;
}

.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}

::v-deep .avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 300px;
  height: 300px;
  line-height: 300px;
  text-align: center;
}

::v-deep .avatar {
  width: 300px;
  height: 300px;
  display: block;
}

.vd_bortop {
  border-top: #DCDFE6 solid 1px;
  padding-top: 20px;
}

.vd_mrt1 {
  margin-top: 1px;
}

::v-deep .el-upload--text {
  height: 300px;
  width: 300px;
}

::v-deep .el-upload-dragger {
  height: 300px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vd_dis{
  display: flex;
  align-items: center;
}
.vd_flex_right{
  cursor: pointer;
  box-sizing: border-box;
  padding-left: 10px;
}
.vd_export2{
  display: flex;
  align-items: center;
  justify-content: space-around;
  // position: absolute;
  // top: 0;
  // right: 120px;
}
.vd_export{
  position: absolute;
  top: 0;
  left: 65px;
}
.vd_edhea {
  position: relative;
}
</style>
